<template>
  <h2 v-html="$markdown(block.item.title)" />
</template>

<script setup lang="ts">
const props = defineProps<{
  block: HeadingBlock;
  customisations: Customisations;
}>();

const fontSize = TailwindConfig.fontSize[props.customisations.heading_block_font_size || "2xl"];
</script>

<style lang="postcss">
.ag-block--heading {
  @apply font-semibold text-gray-900;
  font-size: v-bind("fontSize.fontSize");
  line-height: v-bind("fontSize.lineHeight");

  .block-size-control {
    @apply right-0 top-0;
  }
}
</style>
